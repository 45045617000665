export default class FormSubmitOnce {
  constructor() {
    this.selector = "form[x-submit-once]"
    document.querySelectorAll(this.selector).forEach(this.initComponent, this);
  }
  initComponent(formEl) {
    let submitButtons = [formEl.querySelector(`[type=submit]:not([form])`)];
    if (formEl.id !== "") {
      submitButtons = submitButtons.concat(Array.from(document.querySelectorAll(`[type=submit][form=${formEl.id}]`)))
    }
    submitButtons = submitButtons.filter(el => el)
    formEl.addEventListener("submit", () => {
      submitButtons.forEach(el => el.setAttribute("disabled", true));
      document.body.classList.add('is-waiting');
    })
  }
}
