export default class ElementSwappers {
  // The value of the `swapperSelector` is used to show a specific `swapOptionSelector`
  // and hide all other `swapOptionSelector` within the component
  constructor() {
    this.selector = "[x-component=element-swapper]";
    this.swapOptionSelector = "[x-swap-option]";
    this.swapperSelector = "[x-swapper]";
    document
      .querySelectorAll(this.selector)
      .forEach(this.initComponent, this);
  }

  variantSelector(id) {
    return `[x-swap-option=${id}]`;
  }

  initComponent(el) {
    const swapOptionEls = el.querySelectorAll(this.swapOptionSelector);
    // bind to change event and trigger to initialize
    el.querySelectorAll(this.swapperSelector).forEach((elem) =>
      elem.addEventListener("change", (evt) => {
        let val = evt.currentTarget.value;
        if (evt.currentTarget.type === "radio") {
          val = el.querySelector(`${this.swapperSelector}:checked`).value;
        }
        swapOptionEls.forEach((e) => e.classList.add("hidden"));
        el.querySelectorAll(this.variantSelector(val)).forEach((e) =>
          e.classList.remove("hidden")
        );
      })
    );
  }
}
