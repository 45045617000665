export default class HoverWebsiteImage {
  constructor() {
    this.hoverComponent = "[data-hover-vis]";
    this.hoverItem = "[data-hover-vis-item]";
    this.hoverTarget = "[data-hover-vis-target]";
    this.hoverGroup = "[data-hover-vis-group]"
    document
      .querySelectorAll(this.hoverComponent)
      .forEach(this.initComponent, this);
  }

  variantSelector(id) {
    return `[data-hover-vis-target=${id}]`;
  }

  initComponent(el) {
    const swapOptionEls = el.querySelectorAll(this.hoverTarget);

    el.querySelectorAll(this.hoverItem).forEach((elem) => {
      elem.addEventListener("mouseover", (evt) => {
        let val = evt.currentTarget.getAttribute('data-hover-vis-item');
        let thisGroup = evt.currentTarget.getAttribute('data-hover-vis-group');

        swapOptionEls.forEach((e) => {
          let targetGroup = e.getAttribute('data-hover-vis-group');
          if (thisGroup == targetGroup) {
            e.classList.add("hidden")
          }
        });
        el.querySelectorAll(this.variantSelector("'" + val + "'")).forEach((e) =>
          e.classList.remove("hidden")
        )
      })

      elem.addEventListener("mouseleave", (evt) => {
        swapOptionEls.forEach((e) => {
          e.classList.add("hidden")
          if (e.classList.contains('original-visible')){
            e.classList.remove('hidden');
          }
        });
      })
    })
  }
};
