import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting";

HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);

// Apply the theme
Highcharts.setOptions({
  colors: [
    '#2563EB',
    '#10B981',
    '#F59E0B',
    '#DB2777',
  ],
  credits: {
    enabled: false
  },
  chart: {
    backgroundColor: '#FFF',
    plotBorderColor: '#ccc',
    height: 300,
    style: {
      fontFamily: '"Lato", sans-serif'
    },
    spacingTop: 10,
    spacingLeft: 0,
    spacingRight: 0,
    marginTop: 80,
    marginBottom: 100,
  },

  yAxis: {
    labels: {
      style: {
        fontSize: '14px',
        color: '#042441',
      }
    },

    title: {
      style: {
        fontSize: '14px',
        color: '#042441',
      }
    }
  },
  xAxis: {
    labels: {
      style: {
        fontSize: '14px',
        color: '#042441',
      }
    },
    title: {
      text: 'Period',
      style: {
        fontSize: '14px',
        color: '#042441',
      }
    },
  },

  legend: {
    itemStyle: {
      fontSize: '14px',
      color: '#042441',
    },
  },

  title: {
    style: {
      color: '#042441',
      fontSize: '1.5rem',
      fontWeight: '400'
    }
  },
  plotOptions: {
    pie: {
      dataLabels: {
        style: {
          fontSize: '1rem'
        }
      }
    }
  },
  labels: {
    style: {
      fontSize: '16px',
      color: '#042441',
    }
  },
  navigation: {
    menuStyle: {
      border: "1px solid #e7ecef",
      boxShadow: 'var(--card-shadow)'
    },
    menuItemStyle: {
      fontSize: '16px'
    },
    buttonOptions: {
      symbolStroke: '#042441',
      symbolStrokeWidth: 2,
    },
  },
  exporting: {
    sourceWidth: 1300,
    sourceHeight: 900,
    chartOptions: {
      chart: {
        spacingBottom: 20,
        spacingTop: 20,
        spacingLeft: 20,
        spacingRight: 20,
        marginTop: 40,
        backgroundColor: "#fff",
      }
    },
    buttons: {
      contextButton: {
        menuItems: ["viewFullscreen"]
      }
    },
  },
  responsive: {
    rules: [{
      condition: {
        minWidth: 1171 // max-width based on the design is 1170, so this is triggered in full-screen mode.
      },
      chartOptions: {
        title: {
          margin: 40,
        },
        chart: {
          className: 'full-screen',
          spacingBottom: 80,
          spacingTop: 80,
          spacingLeft: 80,
          spacingRight: 80,
          marginTop: 180,
          marginBottom: 180,
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '26px',
              color: '#042441',
            }
          },
          title: {
            style: {
              fontSize: '26px',
              color: '#042441',
            }
          },
          plotLines: [{
            label: {
              style: {
                fontSize: '26px',
              }
            }
          }],
        },
        xAxis: {
          labels: {
            style: {
              fontSize: '26px',
            }
          },
          title: {
            style: {
              fontSize: '26px',
            }
          },
        },

        legend: {
          itemStyle: {
            fontSize: '26px',
          },
        },

        title: {
          style: {
            fontSize: '42px',
          }
        },
        labels: {
          style: {
            fontSize: '32px',
          }
        },
      }
    }, {
      condition: {
        minWidth: 1440,
      },
      chartOptions: {
        chart: {
          height: 700,
        }
      }
    }]
  },
  lang: {
    thousandsSep: ',',
  },
});

const allRuns = [...new Array(12)].map((_, x) => `${x + 1}`);

function sortTeams(a, b) {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
}

function round(value) {
  if (value == null) return value
  return Math.round(value * 100) / 100;
}

function sortObj(obj) {
  return Object.keys(obj).sort().reduce((newObj, key) => {
    newObj[key] = obj[key];
    return newObj
  }, {})
}

// Experiment results history
const resultsHistoryEl = document.getElementById('results-history');
if (resultsHistoryEl) {
  const history = JSON.parse(resultsHistoryEl.innerHTML);
  const emptyRunsData = Object.fromEntries(allRuns.map((r) => [r, null]));
  const targetInputs = document.querySelectorAll(".targets-toggle");

  function getTargetsData() {
    const targets = [...targetInputs].filter(i => i.checked).map(i => i.value);
    const filtered = history.filter(d => JSON.stringify(targets) == JSON.stringify(d.targets));
    return filtered ? filtered[0] : history[history.length + 1];
  }

  function getChartsData() {
    const targetsData = getTargetsData();
    const timeSeries = targetsData.statistics.time_series;
    return {
      conversion: getPercentageSeries(timeSeries.conversion),
      trafic: getPercentageSeries(timeSeries.allocation),
      profit: getSeries(timeSeries.profit),
    }
  }

  function getSeries(data) {
    const s = Object.entries(sortObj(data)).map(([variant, vData]) => {
      const varData = {...emptyRunsData}
      Object.entries(vData).forEach(([r, value]) => {
        varData[parseInt(r) + 1] = round(value);
      })
      return { name: variant, data: Object.values(varData) }
    })
    return s
  }

  function getPercentageSeries(data) {
    const s = Object.entries(sortObj(data)).map(([variant, vData]) => {
      const varData = {...emptyRunsData}
      Object.entries(vData).forEach(([r, value]) => {
        varData[parseInt(r) + 1] = round(value * 100);
      })
      return { name: variant, data: Object.values(varData) }
    })
    return s
  }

  let chartsData = getChartsData();

  const conversionChart = Highcharts.chart('id_conversion_rate', {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Conversion Rate Over Time',
      align: 'left',
      style: {
        fontSize: '1.125rem',
      },
    },
    subtitle: {
      text: 'Cumulative',
      align: 'left',
      style: {
        fontSize: '1rem',
      },
    },
    yAxis: {
      min: 0,
      title: {
        enabled: false,
        text: 'Conversion %'
      },
      labels: {
        format: '{text}%',
      },
    },
    xAxis: {
      title: {
        text: 'Period',
      },
      labels: {
        step: 1,
      },
      categories: allRuns,
    },
    tooltip: {
      headerFormat: 'Period {point.key}<br/>',
      valueSuffix: '%',
    },
    series: chartsData.conversion,
  });

  const traficChart = Highcharts.chart('id_traffic_over_time', {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Traffic Allocation Per Period',
      align: 'left',
      style: {
        fontSize: '1.125rem',
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        enabled: false,
        text: '% Traffic Allocated',
      },
      labels: {
        format: '{text}%',
      },
    },
    xAxis: {
      title: {
        text: 'Period',
      },
      categories: allRuns,
      labels: {
        step: 1,
      }
    },
    tooltip: {
      headerFormat: 'Period {point.key}<br/>',
      valueSuffix: '%',
    },
    series: chartsData.trafic,
  });

  const profitChart = Highcharts.chart('id_variants_profit', {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Profit per Session Over Time',
      align: 'left',
      style: {
        fontSize: '1.125rem',
      },
    },
    subtitle: {
      text: 'Cumulative',
      align: 'left',
      style: {
        fontSize: '1rem',
      },
    },
    yAxis: {
      min: 0,
      title: {
        enabled: false,
        text: 'Profit',
      },
      labels: {
        format: '${text}',
      },
    },
    xAxis: {
      title: {
        text: 'Period',
      },
      labels: {
        step: 1,
      },
      categories: allRuns,
    },
    tooltip: {
      headerFormat: 'Period {point.key}<br/>',
      valuePrefix: '$',
      valueSuffix: ' by session',
    },
    series: chartsData.profit,
  });

  function updateTable() {
    const targetsData = getTargetsData();
    Object.entries(targetsData.statistics.cumulative_statistics).map(([variant, vData]) => {
      const sessionsEl = document.getElementById(`r-sessions_${variant}`);
      const conversionsEl = document.getElementById(`r-conversions_${variant}`);
      const rateEl = document.getElementById(`r-rate_${variant}`);
      if (sessionsEl) sessionsEl.innerText = vData.sessions.toLocaleString();
      if (conversionsEl) conversionsEl.innerText = vData.conversions;
      if (rateEl) rateEl.innerText = vData.sessions != 0
        ? `${round(vData.conversions / vData.sessions * 100)}%`
        : '--';
      Object.entries(vData.statistics).forEach(([otherVariant, statistics]) => {
        const flipEl = document.getElementById(`r-flip_${variant}_${otherVariant}`);
        if (flipEl) {
          const lift = round(statistics.lift * 100);
          if (lift > 0) {
            flipEl.classList.add("text-green-600")
            flipEl.classList.remove("text-red-600")
          } else if (lift < 0){
            flipEl.classList.add("text-red-600")
            flipEl.classList.remove("text-green-600")
          } else {
            flipEl.classList.remove("text-red-600")
            flipEl.classList.remove("text-green-600")
          }
          flipEl.innerText = statistics.lift
            ? lift
            : "--";
        }
        const pValueEl = document.getElementById(`r-pvalue_${variant}_${otherVariant}`);
        if (pValueEl) {
          let sufix = "";
          if (statistics.p_value < 0.05) {
            pValueEl.classList.add("font-extrabold")
            sufix = "*";
          } else {
            pValueEl.classList.remove("font-extrabold")
          }
          pValueEl.innerText = statistics.p_value
              ? `${round(statistics.p_value)}${sufix}`
            : "--";
        }
      })
    })
  }
  updateTable();

  function updateResults(event) {
    const targets = [...targetInputs].filter(i => i.checked);
    if (targets.length < 1) {
      event.target.checked = true
    }
    chartsData = getChartsData();
    if (chartsData) {
      conversionChart.update({series: chartsData.conversion});
      traficChart.update({series: chartsData.trafic});
      profitChart.update({series: chartsData.profit});
    }
    updateTable();
  }
  targetInputs.forEach(el => el.addEventListener("change", updateResults))
}

// Profits over time
if (document.getElementById('id_profit_over_time')) {
  const profitsEl = document.getElementById('profits-history');
  const profits = JSON.parse(profitsEl.innerHTML);

  if (profits.length) {
    const series = [...new Array(12)].map((_, x) => profits[x] || null);
    Highcharts.chart('id_profit_over_time', {
      chart: {
        type: 'line',
      },
      title: {
        text: 'Profit Over Time',
        align: 'left',
      },
      yAxis: {
        title: {
          text: 'Profit'
        },
      },
      xAxis: {
        categories: allRuns,
      },
      tooltip: {
        formatter: function () {
          return `Period ${this.x}: <b> ${parseInt(this.y / 1000)}K </b>`;
        },
      },
      legend: {
        enabled: false,
      },
      series: [{
        name: 'Profit',
        data: series,
      }],
    });
  }

}

// Profits over time all teams
const teamsResultsEl = document.getElementById('teams-results');
if (teamsResultsEl) {
  const teamsResults = JSON.parse(teamsResultsEl.innerHTML);

  if (document.getElementById("id_profits_by_team")) {
    const teamsProfitsSeries = Object.values(teamsResults)
      .sort(sortTeams)
      .map((team) => ({ name: team.name, data: team.profits_hisotry }));

    const profitChart = Highcharts.chart('id_profits_by_team', {
      chart: {
        type: 'line',
        backgroundColor: 'transparent',
        height: 500,
      },
      title: {
        text: 'Profit Over Time',
        align: 'left',
      },
      yAxis: {
        title: {
          text: 'Profit'
        },
      },
      xAxis: {
        categories: allRuns,
      },
      tooltip: {
        formatter: function () {
          return `Period ${this.x}<br/>${this.series.name} <b> ${parseInt(this.y / 1000)}K </b>`;
        },
      },
      series: teamsProfitsSeries,
    });
    document.getElementById("export_profits_by_team").addEventListener('click', () => {
      profitChart.exportChart();
    });
  }

  if (document.getElementById("id_correct_allocation_ratio_by_team")) {
    const teamsSuccessRatioSeries = Object.values(teamsResults)
      .sort(sortTeams)
      .map((team) => {
        const resultsHistory = team.results_history;
        const data = allRuns.map(
          (run) => resultsHistory[run] ? resultsHistory[run].correct_allocation_ratio * 100 : null)
        return { name: team.name, data }
      });

    const allocationChart = Highcharts.chart('id_correct_allocation_ratio_by_team', {
      chart: {
        type: 'line',
        backgroundColor: 'transparent',
        height: 500,
      },
      title: {
        text: 'Percentage of Traffic Allocated to Optimal Variant',
        align: 'left',
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: ''
        },
      },
      xAxis: {
        categories: allRuns,
        title: {
          text: 'Period'
        },
      },
      tooltip: {
        formatter: function () {
          return `Period ${this.x}<br/>${this.series.name} <b> ${this.y.toFixed(2)}% </b>`;
        },
      },
      series: teamsSuccessRatioSeries,
    });
    document.getElementById("export_correct_allocation_ratio_by_team").addEventListener('click', () => {
      allocationChart.exportChart();
    });
  }

}

// Counterfactual charts
const counterfactualResultsEl = document.getElementById('counterfactual-results');
const teamsAggregateResultsEl = document.getElementById('teams-aggregate');

if (counterfactualResultsEl) {
  const counterfactualResults = JSON.parse(counterfactualResultsEl.innerHTML);
  const teamsAggregateResults = teamsAggregateResultsEl && JSON.parse(teamsAggregateResultsEl.innerHTML);

  const profitsResults = counterfactualResults.profits;
  const allocationResults = counterfactualResults.allocation;

  function getProfitsSeries() {
    const nonContextual = profitsResults[false];
    const contextual = profitsResults[true];
    const keys = ['Random', 'ExploreFirst', 'DynamicAI']
    return [
      { name: 'Non-contextual', data: keys.map((key) => nonContextual[key] * 100) },
      { name: 'Contextual', data: keys.map((key) => contextual[key] * 100) },
    ];
  }

  function getAllocationSeries() {
    return allocationResults ? [
      { name: "Random", data: Object.values(allocationResults["Random"]).map((x) => x * 100) },
      { name: "Explore First", data: Object.values(allocationResults["ExploreFirst"]).map((x) => x * 100) },
      { name: "Dynamic AI", data: Object.values(allocationResults["DynamicAI"]).map((x) => x * 100) },
    ] : [];
  }

  const average = parseFloat(teamsAggregateResults.average.toFixed(2));
  const classBest = parseFloat(teamsAggregateResults.best.toFixed(2));


  const counterfactualProfitsChart = Highcharts.chart('id_counterfactual_profits_chart', {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      height: 400,
      marginRight: 100,
      marginBottom: 140,
    },
    title: {
      text: 'Profit Comparisons',
      align: 'left',
      style: {
        fontSize: '1.125rem',
      }
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        enabled: false,
      },
      tickInterval: 25,
      plotLines: teamsAggregateResults && [
        {
          color: '#F59E0B',
          width: 2,
          value: classBest,
          label: {
            text: `Class Best ${classBest}%`,
            align: "right",
            style: {
              fontSize: '14px',
            },
            x: 100,
          },
          zIndex: 10,
        },
        {
          color: '#DB2777',
          width: 2,
          value: average,
          label: {
            text: `Class Average ${average}%`,
            style: {
              fontSize: '14px;'
            },
            align: "right",
            x: 100,
            y: 16,
          },
          zIndex: 10,
        },
      ],
    },
    xAxis: {
      categories: ['Random', 'Explore First', 'Dynamic AI'],
      title: {
        enabled: false,
      },
    },
    tooltip: {
      valueSuffix: "%"
    },
    series: getProfitsSeries(),
  });

  const counterfactualAllocationChart = Highcharts.chart('id_counterfactual_allocation_chart', {
    chart: {
      type: 'line',
      backgroundColor: 'transparent',
      height: 400,
    },
    title: {
      text: 'Percentage of Traffic Allocated to Optimal Variant',
      align: 'left',
      style: {
        fontSize: '1.125rem',
      }
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        enabled: false,
      },
    },
    xAxis: {
      categories: allRuns,
      title: {
        enabled: false,
      },
    },
    tooltip: {
      valueSuffix: "%"
    },
    series: getAllocationSeries(),
  });
}
