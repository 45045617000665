import { offset } from "../utils";

export default class Tooltips {
  // toggle visibility of tooltips
  constructor() {
    // modal element is determined dynamically from value of `data-editable`
    // close button should be inside the modal element
    this.buttonSelector = "[data-tooltip]";
    this.closeButtonSelector = ".close-button";
    document
      .querySelectorAll(this.buttonSelector)
      .forEach(this.initComponent, this);
  }

  initComponent(el) {
    const tooltipId = el.getAttribute("href");
    const tooltipEl = document.querySelector(tooltipId);

    function close() {
      tooltipEl.classList.add("hidden");
    }

    el.addEventListener("click", (evt) => {
      evt.preventDefault();

      const buttonPos = offset(el);

      tooltipEl.style.top = buttonPos.top - 30;
      tooltipEl.style.left = buttonPos.left - 120;
      tooltipEl.classList.remove("hidden");
      // close when any click is received
      // add delay to prevent this event from being heard and immediately closing
      window.setTimeout(document.addEventListener, 200, "click", close, {
        once: true,
      });
    });
  }
}
