import $ from "jquery";
import tippy from 'tippy.js';

export default function() {

  class ConfigData {
    constructor() {
      this.optionsSelector = '#options-data';
      this.targetsSelector = '#targets-data';
      this.allocationsSelector = '#allocations-data';
      this.optionsEl = $(this.optionsSelector);
      this.targetsEl = $(this.targetsSelector);
      this.allocationsEl = $(this.allocationsSelector);

      this.options = this.optionsEl.length ? JSON.parse(this.optionsEl.text()) : {};
      this.targets = this.targetsEl.length ? JSON.parse(this.targetsEl.text()) : [];
      this.allocations = this.allocationsEl.length ? JSON.parse(this.allocationsEl.text()) : {};

      this.targetsLabels = Object.fromEntries(this.targets.map(([key, label]) => [key, label]));
    }

    getTargetsLabels(targets) {
      return targets.map((t) => this.targetsLabels[t]);
    }
  }

  class StartExperimentForm {
    constructor(configData, hiddenFormsetsForm, experimentCards) {
      this.configData = configData;
      this.hiddenFormsetsForm = hiddenFormsetsForm;
      this.experimentCards = experimentCards;

      this.modalSelector = "#id_experiment_new";

      this.formSelector = '#start-experiment-form';
      this.form = $(this.formSelector).first();
      this.tabPanesSelector = '.tab-pane';
      this.tabs = $(this.tabPanesSelector);

      this.titleSelector = '#new-experiment-title';
      this.title = $(this.titleSelector);
      this.submitButtonTextSelector = '#submit-experiment-text';
      this.submitButtonText = $(this.submitButtonTextSelector);

      this.cancelExperimentSelector = '#cancel-experiment';
      this.cancelExperimentButton = $(this.cancelExperimentSelector);

      this.variablesInputSelector = '[name="variable"]';
      this.variableRadios = $(this.variablesInputSelector);
      this.currentVariable = this.getCurrentVariable();
      this.variableLabels = Object.fromEntries(
        Object.values(this.variableRadios).map((radio) => {
          const label = $(`[for="${radio.id}"]`).text();
          return [radio.value, label];
        })
      );

      this.targetInputSelector = '[name="targets[]"]';
      this.targetInputs = $(this.targetInputSelector);
      this.currentTargets = this.getCurrentTargets();

      this.allocationOptionsSelector = '[id$="_allocation-options"]';
      this.allocationOptionsContainers = $(this.allocationOptionsSelector);
      this.currentAllocationsContainer = this.getCurrentAllocationsContainer();

      this.tabLinksSelector = '[data-tab-link]';
      this.tabLinkButtons = $(this.tabLinksSelector);

      this.websiteSelector = `${this.modalSelector} .website .display-view-wrap`;
      this.website = $(this.websiteSelector);

      this.destinationFormId = null;
      this.linkedCardId = null;

      this.addEventListeners();
      this.toggleAllocationOptions(this.currentVariable);
      this.disableUnavailableTargets();
    }

    addEventListeners() {
      for (const radio of this.variableRadios) {
        radio.addEventListener('input', this.onSelectVariable.bind(this));
      }
      for (const input of this.targetInputs) {
        input.addEventListener('input', this.onSelectTarget.bind(this));
      }
      this.tabLinkButtons.click(this.onTabChange.bind(this));
      this.form.submit(this.onFormSubmit.bind(this));
      this.cancelExperimentButton.click(this.cancelExperiment.bind(this));
    }

    //
    // Internal state handlers
    //

    onSelectVariable(event) {
      this.currentVariable = event.target.value;
      this.currentAllocationsContainer = this.getCurrentAllocationsContainer();
      this.toggleAllocationOptions(event.target.value);
      this.disableUnavailableTargets();
    }

    onSelectTarget(event) {
      this.currentTargets = this.getCurrentTargets();
    }

    onTabChange() {
      this.setVariableText();
      this.setTargetsText();
      this.setSelectedAlocations();
    }

    getCurrentVariable() {
      const checkedVariable = $(this.variablesInputSelector + ':checked');
      if (checkedVariable) {
        return checkedVariable.val();
      }
      return null;
    }

    getCurrentTargets() {
      const checkedTargets = $(this.targetInputSelector + ':checked');
      return Object.values(checkedTargets).map((t) => t.value).filter((v) => !!v);
    }

    getCurrentAllocationsContainer() {
      return this.currentVariable && $(`#${this.currentVariable}_allocation-options`).first();
    }

    getCurrentAllocations() {
      const allocationOptionsItems = this.currentAllocationsContainer.children("li");
      const allocations = {};
      allocationOptionsItems.map(function () {
        const item = $(this);
        const label = item.find("label").text();
        const value = item.find("input").val();
        allocations[label] = value;
      });
      return allocations;
    }

    toggleAllocationOptions() {
      this.allocationOptionsContainers.addClass('hidden');
      if (this.currentAllocationsContainer) {
        this.currentAllocationsContainer.removeClass("hidden");
      }
    }

    disableUnavailableTargets() {
      const unavailableVariablesTargets = this.experimentCards.getUnavailableTargets(this.linkedCardId);
      if (this.currentVariable) {
        const targets = unavailableVariablesTargets[this.currentVariable];
        this.targetInputs.map(function () {
          const input = $(this);
          if (targets.indexOf(input.val()) >= 0) {
            input.prop("checked", false);
            input.prop("disabled", "disabled");
          } else {
            input.removeAttr("disabled");
          }
        });
      }
    }

    disableUnavailableVariables() {
      const unavailableVariablesTargets = this.experimentCards.getUnavailableTargets(this.linkedCardId);
      Object.entries(unavailableVariablesTargets).forEach(([variable, targets]) => {
        if (targets.length == configData.targets.length) {
          this.variableRadios.filter(`[value="${variable}"]`).prop("disabled", true)
        }
      })
    }

    setVariableText() {
      const labelText = this.variableLabels[this.currentVariable];
      if (labelText) {
        $('dialog[open] .variable-value').text(labelText);
      }
    }

    setTargetsText() {
      const labels = this.currentTargets.map((t) => this.configData.targetsLabels[t]);
      function arrayToSentence(arr) {
        return arr.slice(0, -2).join('; ') +
          (arr.slice(0, -2).length ? '; ' : '') +
          arr.slice(-2).join(' and ');
      }
      const text = arrayToSentence(labels)
      if (text) {
        $('dialog[open] .target-values').text(text);
      }
      if (labels.length > 1) {
        $('dialog[open] .target-is-plural').text('s');
      } else {
        $('dialog[open] .target-is-plural').text('');
      }
    }

    setSelectedAlocations() {
      const selectedAllocationsList = $("#selected-alocation-options");
      selectedAllocationsList.empty();
      const allocations = this.getCurrentAllocations();
      Object.entries(allocations).map(([label, value]) => {
        const selectedItem = $("<li></li>", { "class": "selected-item" });
        selectedItem.text(label);
        const details = $("<span></span>", { "class": "selected-item-details" });
        details.text(`${value}%`);
        selectedItem.append(details);
        selectedAllocationsList.append(selectedItem);
      });
    }

    goToFirstTab() {
      this.tabs.removeClass('is-active');
      const firstTab = this.tabs.first()
      firstTab.addClass('is-active');
      firstTab.find('.next-button').removeAttr("disabled");
    }

    //
    // Collaborations
    // HiddenFormsetsForm and ExperimentCards
    //

    onFormSubmit(event) {
      event.preventDefault();
      this.hiddenFormsetsForm.setNewExperimentData(this);

      if (this.linkedCardId) {
        const targets = this.getCurrentTargets();
        const allocations = this.getCurrentAllocations();
        this.experimentCards.cards[this.linkedCardId].update(targets, allocations);
      }
      this.hiddenFormsetsForm.submitForm();
    }

    setFormFromCard(variable, targets, allocations, destinationFormId, cardId) {
      this.destinationFormId = destinationFormId;
      this.linkedCardId = cardId;
      this.cancelExperimentButton.show();
      this.goToFirstTab();
      this.title.text('Revise Experiment');
      //this.submitButtonText.text('Save Experiment');

      this.variableRadios.prop('checked', false);
      this.variableRadios.filter(`[value="${variable}"]`).prop('checked', true);
      this.variableRadios.prop('disabled', 'disabled');
      this.currentVariable = this.getCurrentVariable();

      this.targetInputs.val(targets);
      this.currentTargets = this.getCurrentTargets();
      this.disableUnavailableTargets();

      this.currentAllocationsContainer = this.getCurrentAllocationsContainer();
      this.toggleAllocationOptions(this.currentVariable);
      this.currentAllocationsContainer.find('input').map(function (index) {
        $(this).val(Object.values(allocations)[index]);
      });

      const allVariableClasses = [
        'selected_price',
        'selected_tagline',
        'selected_image',
        'selected_features',
        'selected_cta',
      ];
      allVariableClasses.map((variable) => {
        this.website.removeClass(variable);
      });
      this.website.addClass(`selected_${variable}`);
    }

    resetForm() {
      this.destinationFormId = null;
      this.variableRadios.prop('checked', false);
      this.variableRadios.removeAttr('disabled');
      this.currentVariable = null;
      this.targetInputs.val([]);
      Object.entries(this.configData.options).forEach(([variable, options]) => {
        const allocationOptionsContainer = $(`#${variable}_allocation-options`);
        const values = this.configData.allocations[Object.keys(options).length];
        allocationOptionsContainer.find('input').map(function (index) {
          $(this).val(values[index]);
        })
      });

      this.goToFirstTab();
      this.cancelExperimentButton.hide();

      this.title.text('Set up Experiment');
      //this.submitButtonText.text('Save Experiment');
      this.disableUnavailableVariables();
    }

    cancelExperiment() {
      this.hiddenFormsetsForm.resetForm(this.destinationFormId);
      this.experimentCards.removeCard(this.linkedCardId);
      this.hiddenFormsetsForm.submitForm();
    }
  }

  class HiddenFormsetsForm {
    constructor(configData) {
      this.configData = configData;
      this.formSelector = '#hidden-formsets';
      this.form = $(this.formSelector);
    }

    setNewExperimentData(startExperimentForm) {
      const variable = startExperimentForm.currentVariable;
      const targets = startExperimentForm.getCurrentTargets();
      const allocations = startExperimentForm.getCurrentAllocations();

      const experimentForm = startExperimentForm.destinationFormId ?
        $('#' + startExperimentForm.destinationFormId) :
        $(`.experiment-form[data-variable="${variable}"][data-slot-used="False"]`).first();

      const slotUsed = experimentForm.find('[name$="slot_used"]').first();
      slotUsed.val("True");
      experimentForm.data('slot-used', 'True');

      const targetsSelect = experimentForm.find('[name$="-targets"]').first();
      targetsSelect.val(targets);

      Object.values(allocations).map((value, index) => {
        const input = experimentForm.find(`[name$="${index}-allocation"]`).first()
        input.attr("value", value);
      });
      experimentForm.css('background', 'red');

      return experimentForm.attr('id');
    }

    resetForm(formId) {
      const experimentForm = $('#' + formId);
      experimentForm.css('background', 'blue')

      experimentForm.data('slot-used', 'False');
      const slotUsed = experimentForm.find('[name$="slot_used"]').first();
      slotUsed.val("False");

      const ready = experimentForm.find('[name$="ready_to_run"]').first();
      ready.prop("checked", false);

      const targetsSelect = experimentForm.find('[name$="-targets"]').first();
      targetsSelect.val(Object.keys(this.configData.targetsLabels));

      const variable = experimentForm.data('variable');
      const options = this.configData.options[variable];
      const allocations = this.configData.allocations[Object.keys(options).length];
      experimentForm.find('[name$="-allocation"]').map(function (index) {
        $(this).val(allocations[index]);
      });

    }

    setExperimentsReady() {
      const usedExperiments = $(this.formSelector + ' .experiment-form[data-slot-used="True"]');
      usedExperiments.map(function () {
        const ready = $(this).find('[name$="ready_to_run"]');
        ready.prop("checked", true);
      });
    }

    submitForm() {
      document.body.classList.add('is-waiting');
      this.form.submit();
    }
  }

  class ExperimentCard {
    constructor({
      variable,
      variableLabel,
      targets,
      allocations,
      realExperimentFormId,
      id,
      readyToRun,
      paused,
      configData,
    }) {
      this.configData = configData;
      this.variable = variable;
      this.variableLabel = variableLabel;
      this.targets = targets;
      this.targetsLabels = this.configData.getTargetsLabels(targets);
      this.allocations = allocations;

      this.realExperimentFormId = realExperimentFormId;
      this.id = id;
      this.readyToRun = readyToRun;
      this.paused = paused;

      this.card = null;
    }

    setUpCard(cardEl) {
      this.card = cardEl;
      if (cardEl.hasClass('is-not-yet-run')) {
        this.card.click(this.setExperimentFormValues.bind(this));
      }
    }

    setTargetItems() {
      const targetsList = this.card.find('.card-detail-list').first();
      targetsList.empty();
      Object.values(this.targetsLabels).forEach((label) => {
        const item = $('<li></li>', { 'class': 'card-detail-item' });
        item.text(label);
        targetsList.append(item);
      });
    }

    setExperimentFormValues() {
      startExperimentForm.setFormFromCard(
        this.variable,
        this.targets,
        this.allocations,
        this.realExperimentFormId,
        this.id,
      );
    }

    update(targets, allocations) {
      this.targets = targets;
      this.targetsLabels = this.configData.getTargetsLabels(targets);
      this.allocations = allocations;
      this.setTargetItems();
    }
  }

  class ExperimentCards {
    constructor(configData) {
      this.configData = configData;

      this.newCardsListSelector = '#new-experiments';
      this.runningCardsListsSelector = '#running-experiments';
      this.finishedCardsListsSelector = '#finished-experiments';
      this.newCardsList = $(this.newCardsListSelector);
      this.allCards = $(`${this.newCardsListSelector} .card, ${this.runningCardsListsSelector} .card, ${this.finishedCardsListsSelector} .card`);

      this.cards = {};

      this.runButtonSelector = '#run-experiments';
      this.runButton = $(this.runButtonSelector);

      this.initCards();
    }

    initCards() {
      const cards = [];
      const configData = this.configData;

      this.allCards.map(function () {
        const card = $(this);
        const variable = card.find('[name$="-element"]').val();
        const variableLabel = card.find('[name$="-name"]').val();
        const targets = card.find('[name$="-targets"]').val();
        const allocations = [];
        card.find('[name$="-allocation"]').map(function () {
          allocations.push($(this).val())
        });
        const realExperimentFormId = card.data('form-id');
        const id = card.attr('id');
        const readyToRun = card.data('ready');
        const paused = card.data('paused');
        const cardObj = new ExperimentCard({
          variable,
          variableLabel,
          targets,
          allocations,
          realExperimentFormId,
          id,
          readyToRun,
          paused,
          configData,
        });
        cardObj.setUpCard(card);
        cards.push(cardObj);
        if (card.hasClass("has-error")) {
          card.parents('.kanban-col').addClass('has-error');
        }
      });

      cards.forEach((card) => { this.cards[card.id] = card });
      this.toggleRunButton();
    }

    removeCard(cardId) {
      this.cards[cardId].card.remove();
      delete this.cards[cardId];
      this.toggleRunButton();
    }

    getUnavailableTargets(currentCardId) {
      const variablesTargets = Object.fromEntries(
        Object.keys(this.configData.options).map((variable) => [variable, []])
      );
      Object.entries(this.cards).filter(([id, card]) => id != currentCardId).forEach(([id, card]) => {
        variablesTargets[card.variable] = [...variablesTargets[card.variable], ...card.targets];
      })
      return Object.fromEntries(Object.entries(variablesTargets).map(
        ([variable, targetsList]) => [variable, Array.from(new Set(targetsList))]
      ));
    }

    toggleRunButton() {
      const readyCards = Object.values(this.cards).filter((card) => card.readyToRun);
      if (readyCards.length && readyCards.length == Object.keys(this.cards).length) {
        this.runButton.show();
      } else {
        this.runButton.attr('disabled', 'true');
        let isCol1 = this.runButton.closest('.col1').length > 0;

          tippy('.run-button-parent', {
            content:
              isCol1 ?
                "Add one or more experiments below before running the first Period."
                : "Analyze all results in the first column before running the next Period."
            ,
            allowHTML: true,
            theme: 'light',
          });

      }
    }
  }

  class ConfirmExperimentsModal {
    constructor(hiddenFormsetsForm, experimentCards) {
      this.hiddenFormsetsForm = hiddenFormsetsForm;
      this.experimentCards = experimentCards;

      this.demoIdPrefix = 'confirm_modal';

      this.websiteSelector = `#id_wrap_${this.demoIdPrefix}`;
      this.website = $(this.websiteSelector);

      this.cardsListSelector = '#confirm .experiment-card-list';
      this.cardList = $(this.cardsListSelector);

      this.confirmExperimentsMessageSelector = '#confirm-experiments-message';
      this.confirmExperimentsMessage = $(this.confirmExperimentsMessageSelector);

      this.runButtonSelector = '#confirm-run';
      this.runButton = $(this.runButtonSelector);
      this.addEventListeners();
    }

    addEventListeners() {
      this.runButton.click(this.confirm.bind(this));
    }

    confirm() {
      this.hiddenFormsetsForm.setExperimentsReady();
      this.hiddenFormsetsForm.submitForm();
    }

    setup() {
      const cards = Object.values(this.experimentCards.cards).filter((c) => !c.paused);

      this.cardList.empty();
      cards.forEach((card) => {
        const newCard = card.card.clone();
        newCard.removeAttr('href');
        this.cardList.append(newCard);
      });

      const run = this.confirmExperimentsMessage.data("run");
      const totalRuns = this.confirmExperimentsMessage.data("total-runs");
      if (cards.length > 0 ) {
        const plural = cards.length > 1 ? 's' : '';
        this.confirmExperimentsMessage.text(
          `You are running ${cards.length} experiment${plural} in Period ${run}/${totalRuns}.`
        );
        this.cardList.show();
      } else {
        this.confirmExperimentsMessage.text(
          `You are running Period ${run}/${totalRuns} with no new experiments.`
        );
        this.cardList.hide();
      }

      const cardVariables = cards.map((card) => card.variable);
      const variables = Array.from(new Set(cardVariables));
      const allVariableClasses = [
        'selected_price',
        'selected_tagline',
        'selected_image',
        'selected_features',
        'selected_cta',
      ]
      allVariableClasses.map((className) => {
        this.website.removeClass(className);
      })
      variables.map((variable) => {
        this.website.addClass('selected_' + variable);
      });
    }
  }

  const configData = new ConfigData;
  const hiddenFormsetsForm = new HiddenFormsetsForm(configData);
  const experimentCards = new ExperimentCards(configData);
  const startExperimentForm = new StartExperimentForm(configData, hiddenFormsetsForm, experimentCards);
  const confirmModal = new ConfirmExperimentsModal(hiddenFormsetsForm, experimentCards);

  $('#add-experiment-btn').click(function () {
    startExperimentForm.resetForm();
  });

  $('[data-open-modal="confirm"]').click(function() {
    confirmModal.setup();
  });
}