export default class Dropdown {
  constructor() {
    this.dropdownSelector = ".has-dropdown";
    this.buttonSelector = ".dropdown-button";
    this.linkSelector = ".dropdown-link-checkable";
    this.activeClass = "is-checked";

    this.elements = document.querySelectorAll(this.dropdownSelector);

    this.elements.forEach(this.initComponent, this);
  }

  initComponent(dropdown) {
    const button = dropdown.querySelector(this.buttonSelector);
    const links = dropdown.querySelectorAll(this.linkSelector);
    links.forEach((link) => {
      link.addEventListener("click", (event) => {
        event.preventDefault();
        this.toggleOptions(link, button, links);
      });
    });
    const activeLink = dropdown.querySelector(`${this.linkSelector}.${this.activeClass}`);
    if (activeLink) {
      activeLink.click();
    }
  }

  toggleOptions(link, button, links) {
    button.innerHTML = link.innerHTML;
    links.forEach((l) => l.classList.remove(this.activeClass));
    link.classList.add(this.activeClass);
  }
}
