import jQuery from "jquery";
import select2 from "select2";
import parsley from "parsleyjs";
import { createPopper } from '@popperjs/core';

import tippy from 'tippy.js';

import "./assets/js/components/highcharts";
import ElementSwappers from "./assets/js/components/ElementSwappers";
import HoverWebsiteImage from "./assets/js/components/HoverWebsiteImage";
import Tooltips from "./assets/js/components/Tooltips";
import ReadMore from "./assets/js/components/ReadMore";
import ToggleElementWithLocationHash from "./assets/js/components/ToggleElementWithLocationHash";
import Dropdown from "./assets/js/components/Dropdown";
import ExperimentActionsForm from "./assets/js/components/ExperimentActionsForm";
import FormSubmitOnce from "./assets/js/components/FormSubmitOnce";
import website from "./assets/js/components/website";
import SortableTable from "./assets/js/components/SortableTable";
import TableCSVExport from "./assets/js/components/TableCSVExport";
import initGamePlay from "./assets/js/pages/gamePlay";
import legacy from "./assets/js/components/legacy";


(function ($, window, document) {
  tippy('[data-tippy-content]', {
    allowHTML: true,
    theme: 'light',
  });

  new ElementSwappers();

  new HoverWebsiteImage();

  new Tooltips();

  new ReadMore();

  new ToggleElementWithLocationHash();

  new Dropdown();

  new FormSubmitOnce();

  new SortableTable();

  new TableCSVExport();

  website();

  legacy();

  // Init Index page
  if (document.getElementById('Controls')) {
    initGamePlay();
  }

  // Init Details page

  if (document.getElementById('experiment-details')) {
    new ExperimentActionsForm();
  }

})(jQuery, window, document);
