export default function() {
  // homepage hover demo component
  var buttonSelector = document.querySelectorAll('[data-demo-target]');
  var allTargetAreas = document.querySelectorAll('[data-demo-id]');

  // highlight thumbnail parts when buttons are hovered
  if (buttonSelector) {
    buttonSelector.forEach(function (e) {
      var targetId = e.getAttribute('data-demo-target');
      var matchingElm = document.querySelector('[data-demo-id=' + targetId + ']');

      function highlightThis(e) {
        matchingElm.classList.add('is-highlighted');
      }

      function removeHighlight(e) {
        matchingElm.classList.remove('is-highlighted');
      }

      e.addEventListener('mouseover', highlightThis);
      e.addEventListener('mouseout', removeHighlight);
    });
  }

  var variableRadios = document.querySelectorAll('[name="variable"]');
  if (variableRadios) {
    variableRadios.forEach(function (e) {
      function makeSelection(e) {
        var siteWrap = document.getElementById('id_wrap_start_exp_modal');
        siteWrap.className = "display-view-wrap";
        siteWrap.classList.add('selected_' + this.value);
      }

      e.addEventListener('input', makeSelection);
    });
  }

  // highlight buttons when thumbnail parts are hovered
  if (allTargetAreas) {
    allTargetAreas.forEach(function (e) {
      var targetId = e.getAttribute('data-demo-id');
      var matchingElm = document.querySelector('[data-demo-target=' + targetId + ']');

      function removeHighlight() {
        var allElms = document.querySelectorAll('[data-demo-target]');
        allElms.forEach(function (e) { e.classList.remove('is-selected') });
      }

      function highlightThis(e) {
        e.preventDefault();
        removeHighlight();
        if (matchingElm) matchingElm.classList.add('is-selected');
      }

      e.addEventListener('mouseover', highlightThis);
      e.addEventListener('ontouchstart', highlightThis);
      e.addEventListener('mouseout', removeHighlight);
    });
  }

  // add class to website parent element when a variable radio button is selected.

  buttonSelector.forEach(function (e) {
    var targetId = e.getAttribute('data-demo-target');
    var matchingElm = document.querySelector('[data-demo-id=' + targetId + ']');

    function highlightThis(e) {
      matchingElm.classList.add('is-highlighted');
    }

    function removeHighlight(e) {
      matchingElm.classList.remove('is-highlighted');
    }

    e.addEventListener('mouseover', highlightThis);
    e.addEventListener('mouseout', removeHighlight);
  });
}