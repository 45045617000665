import $ from "jquery";

export default class ExperimentActionsForm {
  constructor() {
    this.formSelector = '#action-form';
    this.form = $(this.formSelector);

    this.submitButtonSelector = this.formSelector + ' [type="submit"]';
    this.submitButton = $(this.submitButtonSelector);

    this.actionsSelector = this.formSelector + ' [name="action"]';
    this.actionInputs = $(this.actionsSelector);

    this.pauseSelector = this.formSelector + ' [name="paused"]';
    this.pauseExperimentInput = $(this.pauseSelector);

    this.setAllocationsFormSelector = '#set-allocations-form';
    this.setAllocationsForm = $(this.setAllocationsFormSelector);

    this.allocationInputsSelector = this.formSelector + ' [name$="allocation"]';
    this.allocationInputs = $(this.allocationInputsSelector);
    this.allocationParent = this.allocationInputs.closest(".radio-decision-item");
    this.allocationErrorSelector = this.formSelector + ' .error-message';
    this.allocationError = $(this.allocationErrorSelector);

    this.finalizeExperimentFormSelector = '#finalize-experiment-form';
    this.finalizeExperimentForm = $(this.finalizeExperimentFormSelector);

    this.form.submit(this.onFormSubmit.bind(this));
    this.allocationInputs.on('blur', this.validateAllocations.bind(this));
    this.actionInputs.click(this.toggleForms.bind(this))

    this.setAllocationsForm.hide();
    this.finalizeExperimentForm.hide();
  }

  onFormSubmit() {
    const action = this.actionInputs.filter(':checked').val();
    if (action == 'pause') {
      this.pauseExperimentInput.val('True');
    }
  }

  toggleForms(event) {
    const thisWrapper = $(event.target).closest('.radio-decision-item');
    const thisForm = thisWrapper.find('.radio-decision-form');

    const otherWrappers = $('.radio-decision-item').not(thisWrapper);
    const otherForms = otherWrappers.find('.radio-decision-form');

    otherForms.hide();
    otherWrappers.removeClass('is-expanded');
    if (thisForm.length) {
      thisForm.show();
      thisWrapper.addClass('is-expanded');
    }
  }

  validateAllocations() {
    let total = 0;
    this.allocationInputs.map(function () {
      total += Number($(this).val());
    })
    if (total == 100) {
      this.allocationError.addClass('hidden');
      this.submitButton.removeAttr("disabled");
    } else {
      this.allocationError.removeClass('hidden');
      this.submitButton.attr("disabled", "disabled");
    }
  }
}
