import $ from "jquery";
import dialogPolyfill from "dialog-polyfill";
import parsely from "parsleyjs";

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

// Miscelaneous functions from previous development too complicated to unwrap
export default function() {
  // fix height of container to transformed thumbnail
  var displayViewWraps = document.querySelectorAll('.display-view-wrap');

  function forceHeights() {
    displayViewWraps.forEach(function (e) {
      var dimensions = e.getBoundingClientRect();
      e.parentElement.style.height = dimensions.height + 'px';
    });
  }

  if (displayViewWraps) {
    forceHeights();
    $(document).ready(function () {
      forceHeights();
    });

    function resizedw() {
      forceHeights();
    }

    var doit;
    window.onresize = function () {
      clearTimeout(doit);
      doit = setTimeout(resizedw, 250);
    };
  }

  // modal utilities
  function registerModal(e) {
    var dialogLink = e.getAttribute('data-open-modal');
    var matchingDialog = document.getElementById(dialogLink);
    var scrollingContainer = matchingDialog.querySelector('.scroll-wrap');

    // polyfill
    dialogPolyfill.registerDialog(matchingDialog);

    function openThisDialog(e) {
      //  if a dialog is being called from another dialog, close the first one.
      var anyOpenDialog = document.querySelector('dialog[open]');
      if (anyOpenDialog) {
        enableBodyScroll(scrollingContainer);
        anyOpenDialog.close();
      }

      matchingDialog.showModal();
      disableBodyScroll(scrollingContainer);

      var closeButtons = matchingDialog.querySelectorAll('[data-close-button]');

      closeButtons.forEach(function (e) {
        e.onclick = function () {
          matchingDialog.close();
          enableBodyScroll(scrollingContainer);
        };
      });

      // run height calculation on website demo
      forceHeights();

      // on init, check validity and handle next button
      checkSectionValidity();

      // then do the same on input change
      $('.tab-pane.is-active input').on('change', function () {
        checkSectionValidity();
      });

      e.preventDefault();


    }
    e.addEventListener('click', openThisDialog);
  }

  var modalLink = document.querySelectorAll('[data-open-modal]');
  if (modalLink) {
    modalLink.forEach(registerModal);
  }

  // handle initial close buttons
  let dialogOpenOnLoad = document.querySelector('dialog[open]');
  if (dialogOpenOnLoad) {
    let closeButtons = dialogOpenOnLoad.querySelectorAll('[data-close-button]');
    let scrollingContainer = dialogOpenOnLoad.querySelector('.scroll-wrap');
    closeButtons.forEach(function (e) {
      e.onclick = function () {
        dialogOpenOnLoad.close();
        enableBodyScroll(scrollingContainer);
      };
    });
  }

  // Setup parsely JS validation helpers
  var $sections = $('.tab-pane');
  function curIndex() {
    return $sections.index($sections.filter('.is-active'));
  }

  // Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
  $sections.each(function (index, section) {
    $(section).find(':input').attr('data-parsley-group', 'block-' + index);
  });

  function checkSectionValidity(nextButtonHref) {
    // This handles whether the 'next' button is available
    var parsleyIsValid = $('[data-start-experiment-form]').parsley().isValid({ group: 'block-' + curIndex() });

    // if we're in the allocations field, check that they equal 100.
    var $customValidatorFields = $('.tab-pane.is-active [data-make-100]:visible')
    var hasCustomValidator = $customValidatorFields.length > 0;
    var totalVals = 100;
    if (hasCustomValidator) {
      var totalVals = 0;

      $customValidatorFields.each(function () {
        totalVals += +($(this).val());
      });
    }

    var $thisNextButton = $('.tab-pane.is-active .next-button');
    if (parsleyIsValid && totalVals === 100) {
      $thisNextButton.removeAttr('disabled');
      if (nextButtonHref) {
        $thisNextButton
        .attr('href', nextButtonHref)
        .attr('data-tab-link', '')
      }
      $('.tab-pane.is-active .error-message').addClass('hidden');
    } else {
      $thisNextButton.attr('disabled', 'true');
      if (nextButtonHref) {
        $thisNextButton
        .removeAttr('data-tab-link')
        .removeAttr('href')
      }
      $('.tab-pane.is-active .error-message').removeClass('hidden');
    }

    const requiredValidatorFields = $('.tab-pane.is-active [data-required]:visible');
    if (requiredValidatorFields.length > 0) {
      if (requiredValidatorFields.filter(':checked').length > 0) {
        $thisNextButton.removeAttr('disabled');
        if (nextButtonHref) {
          $thisNextButton
          .attr('href', nextButtonHref)
          .attr('data-tab-link', '')
        }
      } else {
        $thisNextButton.attr('disabled', 'true');
        if (nextButtonHref) {
          $thisNextButton
          .removeAttr('data-tab-link')
          .removeAttr('href')
        }
      }
    }
  }

  // Experiment setup tabs, with form validation. Note that any link can
  // have the data-tab-link attribute, so this logic applies to the 'next'
  // buttons too.

  $("[data-tab-link]").click(function () {
    var newTabHash = $(this).attr("href");
    var $newTab = $(newTabHash);

    // on init, check validity and handle next button
    checkSectionValidity();

    $('[data-start-experiment-form]').parsley().whenValidate({
      group: 'block-' + curIndex()
    }).done(function () {
      if ($newTab.length > 0) {
        $('.tab-pane').removeClass('is-active');
        $newTab.addClass("is-active");
      }

      // make tab appear active, even if it wasn't the click target
      var $newActiveTabLink = $(".setup-tab-link[href='" + newTabHash + "']");

      // make current tab 'complete'
      $(".setup-tab-item.is-active").addClass('is-complete');

      // then clear all active classes
      $(".setup-tab-item").removeClass("is-active");

      // then add the new active class
      $newActiveTabLink.parent().addClass("is-active");

      var nextButtonHref = $('.tab-pane.is-active .next-button').attr('href');

      checkSectionValidity(nextButtonHref);

      // then on change.
      $('.tab-pane.is-active [type=radio], .tab-pane.is-active [type=checkbox]').on('change', function () {
        checkSectionValidity(nextButtonHref);
      });


      // validate fields to 100
      $('.tab-pane.is-active [data-make-100]').on('input', function () {
        checkSectionValidity(nextButtonHref);
      });

      const requiredValidatorFields = $('.tab-pane.is-active [data-required]:visible');
      requiredValidatorFields.change(function() {
        if (requiredValidatorFields.length > 0) {
          if (requiredValidatorFields.filter(':checked').length > 0) {
            $('.tab-pane.is-active .error-message').addClass('hidden');
          } else {
            $('.tab-pane.is-active .error-message').removeClass('hidden');
          }
        }
      })
      return false;
    });
  });
}
