export default class ToggleElementWithLocationHash {
  // show/hide elements in the right-side controls pane
  constructor() {
    // change active element button
    this.buttonSelector = "[data-toggle-this]";
    // elements to toggle
    this.selector = "[data-toggle-id]";
    this.els = document.querySelectorAll(this.selector);
    // set initial section on page based on hash.
    if (window.location.hash.length > 0) {
      this.showEl(window.location.hash.split("#")[1]);
    }
    document
      .querySelectorAll(this.buttonSelector)
      .forEach(this.initComponent, this);
  }

  toggleSelector(id) {
    return `[data-toggle-id="${id}"]`;
  }

  showEl(targetId) {
    const el = document.querySelector(this.toggleSelector(targetId));
    if (el) {
      this.els.forEach((el) => el.classList.add("hidden"));
      el.classList.remove("hidden");
      window.location.hash = targetId;
      console.log('this should work ' + { targetId });
    }
  }

  initComponent(el) {
    const targetId = el.getAttribute("data-toggle-this");
    el.addEventListener("click", (evt) => {
      evt.preventDefault();
      this.showEl(targetId);
    });
  }
}
