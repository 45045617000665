export default class ReadMore {
  constructor() {
    // modal element is determined dynamically from value of `data-editable`
    // close button should be inside the modal element
    this.selector = ".is-expandable";
    document
      .querySelectorAll(this.selector)
      .forEach(this.initComponent, this);
  }

  initComponent(el) {
    const toggleButton = el.querySelector('[data-read-more-link]');
    const toggleThis = el.querySelector('.expand-this');

    function toggle() {
      toggleThis.classList.toggle("hidden");
      if (toggleThis.classList.contains("hidden")) {
        toggleButton.innerHTML = 'Learn more';
      } else {
        toggleButton.innerHTML = 'Collapse';
      }
    }

    toggleButton.addEventListener("click", (evt) => {
      evt.preventDefault();
      toggle();
    });
  }
}
