// https://www.geeksforgeeks.org/how-to-export-html-table-to-csv-using-javascript/
function tableToCSV(table, fileName) {
  var csvData = [];

  var rows = table.querySelectorAll('tr');
  for (var i = 0; i < rows.length; i++) {
    var cols = rows[i].querySelectorAll('td,th');
    var csvrow = [];
    for (var j = 0; j < cols.length; j++) {
      csvrow.push(`\"${cols[j].innerHTML}\"`);
    }
    csvData.push(csvrow.join(","));
  }
  csvData = csvData.join('\n');

  const CSVFile = new Blob([csvData], {
    type: "text/csv"
  });

  var temp_link = document.createElement('a');
  temp_link.download = `${fileName}.csv`;
  var url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);
  temp_link.click();
  document.body.removeChild(temp_link);
}

export default class TableCSVExport {
  constructor() {
    this.exportButtons = document.querySelectorAll(".export-table");
    this.exportButtons.forEach(this.initComponent, this);
  }

  initComponent(button) {
    const tableId = button.dataset.table;
    const table = document.getElementById(tableId);
    const fileName = button.dataset.name;
    button.addEventListener("click", function () {
      tableToCSV(table, fileName);
    });
  }
}
